<template>
  <LayoutSuperadmin>
    <template v-slot:title>Received Quotes</template>
    <div class="p-0 p-md-3 pl-md-5 pr-md-5 overflow-hidden">
      <div class="quote-list m-auto">
        <GoBackBtn>Back</GoBackBtn>
        <AppLoading v-if="!order"></AppLoading>
        <div v-else>
          <!-- Vendor Detail Row -->
          <div class="row p-3 p-md-0 text-uppercase">
            <!-- Project Name -->
            <div class="col-12 col-md">
              <AppTextLabeled label="PROJECT NAME">
                <h2 class="">{{ order.project_name }}</h2>
              </AppTextLabeled>
            </div>
            <!-- Bidding Counter -->
            <div class="col-12 col-md-4 text-md-right">
              <AppTextLabeled
                label="BIDDING ENDS IN"
                class="text-primary"
                labelColor="primary"
              >
                <h2 class="">
                  {{ biddingTime }}
                </h2>
              </AppTextLabeled>
            </div>
          </div>
          <!-- Order Detail Row -->
          <div class="mt-3 p-3 p-md-0">
            <div class="row">
              <!-- RFQ Number -->
              <div class="col-6 col-md">
                <AppTextLabeled label="RFQ ID" class="font-weight-5">
                  #{{ order.id | rfqFormat }}
                </AppTextLabeled>
              </div>
              <!-- PO NO. Number -->
              <div class="col-6 col-md">
                <AppTextLabeled label="PO NO." class="font-weight-5">
                  {{ order.po_number || 'N/A' }}
                </AppTextLabeled>
              </div>
              <!-- Product Type -->
              <div class="col-12 col-md-3 mb-2 mb-md-0 mt-2 mt-md-0">
                <AppTextLabeled label="Product Type" class="font-weight-4">
                  Materials & Supplies
                </AppTextLabeled>
              </div>
              <!-- Order Date -->
              <div class="col-6 col-md">
                <AppTextLabeled label="Order Date" class="font-weight-5">
                  {{ order.created_at | readableDate(false) }}
                </AppTextLabeled>
              </div>
              <!-- Bidding End Date -->
              <div class="col-6 col-md">
                <AppTextLabeled label="End Bidding Date" class="font-weight-5">
                  {{ order.bidding_end_date | readableDate(false, true) }}
                </AppTextLabeled>
              </div>
              <!-- Delivery Date -->
              <div class="col-6 col-md-2 mt-2 mt-md-0">
                <AppTextLabeled label="Delivery Date" class="font-weight-5">
                  {{ order.delivery_date | readableDate(false, true) }}
                </AppTextLabeled>
              </div>
            </div>
          </div>

          <AppDivider class="mt-3" color="gray"></AppDivider>
          <!-- Auto Approve Options -->
          <div
            class="
              mt-md-3
              p-3 p-md-0
              d-flex
              flex-column flex-md-row
              alin-items-start
              align-items-md-center
            "
          >
            <p class="m-0 pb-xs-4">
              <span>Auto Approve Options</span>
              <AppTooltip
                class="ml-md-2 d-inline"
                tooltipText="Quote will auto approve once the bidding date is due"
              ></AppTooltip>
            </p>
            <AppBtn
              outline
              color="primary"
              class="d-block ml-3"
              @click="confirmAutoApprove"
              :loading="!quotes"
            >
              1-click Vendor Match
            </AppBtn>
          </div>

          <!-- Quote Proposal Table -->
          <AppLoading v-if="!quotes"></AppLoading>
          <div v-else class="mt-3">
            <!-- Web View -->
            <div class="d-none d-md-block">
              <AppTable
                v-if="quotes"
                class="quote-list-table"
                :headers="tableHeaders"
                :items="quotes.data"
                :total="quotes.total"
                :currentPage="quotes.current_page"
                :sort="sorts"
                @sort="sortBy"
                @pageChange="pageChange"
                paginationClass="bg-gray-light-3"
              >
                <template v-slot:item="{ shownItem }">
                  <td>{{ shownItem.user.company.name }}</td>
                  <td>
                    <span v-if="shownItem.delivery_date">
                      {{ shownItem.delivery_date | readableDate(false, true) }}
                    </span>
                    <span v-else>N/A</span>
                  </td>
                  <td>{{ shownItem.order | addressFormat }}</td>
                  <td>{{ shownItem | totalPrice }}</td>
                  <td>{{ getItemStatus(shownItem) | toTitleCase }}</td>
                  <td>
                    <router-link
                      v-if="
                        ![
                          STATUS.QUOTE_APPROVED.quote_value,
                          STATUS.QUOTE_REJECTED.quote_value,
                        ].includes(shownItem.status)
                      "
                      :to="{
                        name: 'QuoteView',
                        params: { id: shownItem.id },
                      }"
                      class="quote-list-table-action text-decoration-none"
                    >
                      <div
                        class="d-flex align-items-center justify-content-center"
                      >
                        <!-- <AppIcon name="document" class="text-info"></AppIcon> -->
                        <AppBtn class="quote-list-table-action-btn ml-4 mr-0">
                          View Quotation
                        </AppBtn>
                      </div>
                    </router-link>

                    <template v-else>
                      <router-link
                        v-if="
                          shownItem.status ===
                            STATUS.QUOTE_APPROVED.quote_value &&
                          shownItem.delivered_at === null
                        "
                        :to="{
                          name: 'QuoteView',
                          params: { id: shownItem.id },
                          query: { view_quote: true },
                        }"
                        class="quote-list-table-action text-decoration-none"
                      >
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-center
                          "
                        >
                          <AppIcon
                            name="checkmark"
                            class="text-success"
                          ></AppIcon>
                          <AppBtn text color="success" class="ml-4 mr-0">
                            Quote Approved
                          </AppBtn>
                        </div>
                      </router-link>
                      <router-link
                        v-else-if="
                          shownItem.status ===
                            STATUS.QUOTE_APPROVED.quote_value &&
                          shownItem.delivered_at !== null
                        "
                        :to="{
                          name: 'QuoteView',
                          params: { id: shownItem.id },
                        }"
                        class="quote-list-table-action text-decoration-none"
                      >
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-center
                          "
                        >
                          <AppIcon
                            name="checkmark"
                            class="text-success"
                          ></AppIcon>
                          <AppBtn text color="success" class="ml-4 mr-0">
                            Quote Approved
                          </AppBtn>
                        </div>
                      </router-link>
                      <router-link
                        v-else
                        :to="{
                          name: 'QuoteView',
                          params: { id: shownItem.id },
                        }"
                        class="quote-list-table-action text-decoration-none"
                      >
                        <div class="d-flex align-items-center">
                          <AppBtn text color="gray" class="ml-4 mr-0">
                            Quote Declined
                          </AppBtn>
                        </div>
                      </router-link>
                    </template>
                  </td>
                </template>
              </AppTable>
            </div>
            <!-- Mobile View -->
            <div class="d-md-none bg-gray-light-4">
              <span class="pl-3 p-2 w-100 d-block bg-gray-dark-7 text-white">
                Received Quotes
              </span>

              <AppCard
                class="mb-3 mx-2 mt-2 cursor-pointer app-card"
                v-for="(shownItem, index) in quotes.data"
                :key="`mobile-quote-${index}`"
                hoverable
              >
                <router-link
                  tag="div"
                  :to="{
                    name: 'QuoteView',
                    params: { id: shownItem.id },
                  }"
                  class="p-3"
                >
                  <!-- company -->
                  <span class="mb-4 d-block font-weight-6">
                    {{ shownItem.user.company.name }}
                  </span>
                  <!-- Delivery date -->
                  <div class="row mb-2">
                    <div class="col-5">Delivery Date</div>
                    <div class="col-7 font-weight-6">
                      {{ shownItem.delivery_date | readableDate(false, true) }}
                    </div>
                  </div>
                  <!-- Location -->
                  <div class="row mb-2">
                    <div class="col-5">Location</div>
                    <div class="col-7">
                      {{ shownItem.order | addressFormat }}
                    </div>
                  </div>
                  <!-- Total Quote -->
                  <div class="row">
                    <div class="col-5">Total Quote</div>
                    <div class="col-7 font-weight-6">
                      {{ shownItem | totalPrice }}
                    </div>
                  </div>
                </router-link>
              </AppCard>
            </div>

            <div class="d-md-none mt-5 mb-3 mx-5">
              <AppBtn class="w-100" :loading="loading" @click="cloneRfq">
                Clone Order
              </AppBtn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ActionModal
      v-model="autoApproveModal"
      @confirm="autoApprove"
      @cancel="autoApproveModal = false"
    >
      <template v-slot:title
        >Are you sure you want to auto select a quotation?</template
      >
      <template v-slot:description> Click confirm to proceed </template>
    </ActionModal>

    <SuccessModal
      v-model="autoApproveSuccessModal"
      @confirm="finishAutoApprove"
    >
      <template v-slot:description> 1-click vendor match successful </template>
    </SuccessModal>

    <ActionModal
      state="error"
      header="No quotation eligible for auto-approve"
      description=""
      v-model="autoApproveFailedModal"
      @cancel="autoApproveFailedModal = false"
    >
    </ActionModal>

    <SuccessModal
      v-model="cloned"
      @confirm="$router.push({ name: 'OrderRequestList' })"
    >
      <template v-slot:title> Order Cloned </template>
      <template v-slot:description>
        Click "Proceed" to return to Order list
      </template>
      <template v-slot:action-btn-text> Proceed </template>
    </SuccessModal>
  </LayoutSuperadmin>
</template>

<script>
import { DateTime } from 'luxon';
import GoBackBtn from '@/components/Partial/GoBackBtn.vue';
import AppTextLabeled from '@/shared/elements/AppTextLabeled.vue';
import AppDivider from '@/shared/elements/AppDivider.vue';
import AppTooltip from '@/shared/elements/AppTooltip.vue';
import AppTable from '@/shared/elements/AppTable.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import _countdownConfigs from '@/shared/mixins/_countdownConfigs';
import _readableDateTime from '@/shared/mixins/_readableDateTime';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import _rfqFormat from '@/shared/mixins/_rfqFormat';
import _strings from '@/shared/mixins/_strings';
import Orders from '@/shared/api/Orders';
import AppLoading from '@/shared/elements/AppLoading.vue';
import OrderConstants from '@/shared/constants/OrderConstants';
import AppCard from '@/shared/elements/AppCard.vue';
import LayoutSuperadmin from '../../../../components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin.vue';
import ActionModal from '@/components/Partial/Modals/ActionModal.vue';
import SuccessModal from '@/components/Partial/Modals/SuccessModal.vue';

export default {
  name: 'BuyerQuoteReqestList',

  components: {
    GoBackBtn,
    AppTextLabeled,
    AppDivider,
    AppTooltip,
    AppTable,
    AppBtn,
    AppIcon,
    AppLoading,
    AppCard,
    LayoutSuperadmin,
    ActionModal,
    SuccessModal,
  },

  mixins: [
    _countdownConfigs,
    _readableDateTime,
    _appApiHelper,
    _rfqFormat,
    _strings,
  ],

  data() {
    return {
      STATUS: OrderConstants.STATUS,
      id: this.$route.params.id,
      order: false,
      sorts: [{ item: 'order_quote_created_at', orderBy: 'DESC' }],
      currentPage: 1,
      quotes: null,
      autoApproveModal: false,
      autoApproveSuccessModal: false,
      autoApproveFailedModal: false,
      cloned: false,
    };
  },

  computed: {
    tableHeaders() {
      return [
        {
          text: 'Company',
          value: 'company_name',
          sortable: false,
        },
        {
          text: 'Delivery Date',
          value: 'order_delivery_date',
          desc:
            this.sorts.length > 0 ? this.isDesc('order_delivery_date') : false,
        },
        {
          text: 'Location',
          value: 'address',
          sortable: false,
        },
        {
          text: 'Total Quote',
          value: 'order_total',
          desc: this.sorts.length > 0 ? this.isDesc('order_total') : false,
        },
        {
          text: 'Status',
          value: 'status',
          desc: this.sorts.length > 0 ? this.isDesc('status') : false,
        },
        {
          text: '',
          sortable: false,
        },
      ];
    },
    biddingTime() {
      if (this.countdownDisplayRaw.valueOf() > 0) {
        return `${this.countdownDisplayRaw.toFormat('hhHH : mm')}mm`;
      } else {
        return 'END DATE REACHED';
      }
    },
  },

  filters: {
    addressFormat(order) {
      return `${order.address}, ${order.city}, ${order.state} ${order.zip_code}`;
    },
    totalPrice(quote) {
      let total = 0;
      quote.products.forEach((product) => {
        total += product.total_price;
      });
      return `$ ${total}`;
    },
  },

  methods: {
    getItemStatus(item) {
      if (item.completed_at !== null) {
        return 'Completed';
      }

      if (item.delivered_at !== null) {
        return 'Shipped';
      }

      return item.status;
    },
    async getOrderDetails() {
      const apiResponse = await Orders.getOrder(this.id);
      if (this.error) {
        alert('there was an error in your request. Please refresh the page');
      } else {
        this.order = apiResponse.data;

        if (this.order.bidding_end_date !== null) {
          this.end = DateTime.fromFormat(
            this.order.bidding_end_date,
            'yyyy-MM-dd HH:mm:ss'
          );
        }
        this.startTick();
      }
    },
    async getQuotes(page = 1) {
      const sorts = {};
      this.sorts.forEach((value) => {
        sorts[value.item] = value.orderBy.toLowerCase();
      });

      const apiResponse = await Orders.getQuotes({
        params: { page, order_id: this.id, ...sorts },
      });

      this.quotes = apiResponse.data;
    },
    sortBy({ item, desc }) {
      const existingIndex = this.sorts.findIndex(
        (value) => value.item === item
      );
      if (existingIndex < 0) {
        this.sorts.push({ item, orderBy: desc ? 'DESC' : 'ASC' });
      } else {
        this.sorts[existingIndex].orderBy = desc ? 'DESC' : 'ASC';
      }

      this.getQuotes();
    },
    async pageChange(page) {
      this.pendingOrders = await this.getQuotes(page);
    },
    isDesc(value) {
      return this.sorts.find((val) => val.item === value)?.orderBy === 'DESC';
    },
    confirmAutoApprove() {
      this.autoApproveModal = true;
    },
    autoApprove() {
      this.autoApproveModal = false;

      Orders.autoApproveOrder(this.id)
        .then(() => {
          this.autoApproveSuccessModal = true;
        })
        .catch(() => {
          this.autoApproveFailedModal = true;
        });
    },
    finishAutoApprove() {
      this.autoApproveSuccessModal = false;

      this.$router.go();
    },
    async cloneRfq() {
      const response = await Orders.cloneOrder(this.id);

      if (response.status === 200) {
        this.cloned = true;
      }
    },
  },

  mounted() {
    this.getOrderDetails();
    this.getQuotes();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_breakpoints';

.quote-list {
  max-width: 1200px;
}
@include media-sm('below') {
  .pb-xs-4 {
    padding-bottom: 1.5rem !important;
  }
}

.app-card {
  border: 1px solid orange;
  border-radius: 5%;
}
</style>
